<template>
  <div class="Enter-box">
    <p class="Enterprise-p"><i></i><span>企业认证 / 续费</span></p>
    <div class="Enterprise">
      <!-- <div class="Enterprise-title">
        <span></span>
        <p>企业信息</p>
      </div> -->
      <div class="Enterprise-confirm">
        <div class="Enterprise-state">
          <span>确定订单</span>
          <span>支付</span>
          <span>支付完成</span>
        </div>
        <p><span>企业名称：</span><span>{{qy_data.ent_name}}</span></p>
        <p><span>统一社会信用代码：</span><span>{{qy_data.uscc}}</span></p>
        <p><span>法人姓名：</span><span>{{qy_data.p_mng_name}}</span></p>
        <p><span>法人手机号：</span><span>{{qy_data.p_mng_mobile}}</span></p>

        <div class="Enterprise-confirm-type">
          <span>续费类型：</span>
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="年费" name="first">
              <div></div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="Enterprise-confirm-deadline">
          <p>购买期限：</p>
          <el-input-number
            v-model="num"
            controls-position="right"
            @change="handleChange"
            :min="1"
            :max="10"
          ></el-input-number>
          <span>年</span>
        </div>
        <div class="Enterprise-confirm-deadline">
          <p>购买节点：</p>
          <el-input-number
            v-model="numS"
            controls-position="right"
            @change="handleChange"
            :min="10"
            :max="100"
          ></el-input-number>
          <span>个</span>
        </div>
        <div class="Enterprise-money">
          <p>合计金额：</p>
          <span>￥{{ money }}</span>
          <!-- <input type="text" v-model="money"> -->
        </div>
        <div class="nextStep">
          <!-- <a href="/EnterprisePayment" >下一步</a> -->
          <a  @click="EnterprisePayment" >下一步</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      num: 1, // 年限
      numS: 1, // 节点
      money: 13000,

      qy_data:{
        // ent_name:'',// 企业名
        // uscc:'', // 企业信用代码
        // p_mng_name: '', // 法人
        // p_mng_mobile:'', // 法人手机号
      },
      order_type:'' // 

    }
  },
  mounted() {
    console.log(this.$route);
      let ent_idX = this.$route.params.ent_idX
      let order_type = this.$route.params.order_type

      let itemobj = this.$route.params.item
      console.log('itemobj',itemobj);
      // let data = {
      //   ent_name:itemobj.ent_name,// 企业名
      //   uscc:itemobj.uscc, // 企业信用代码
      //   p_mng_name: itemobj.p_mng_name, // 法人
      //   p_mng_mobile:itemobj.p_mng_mobile, // 法人手机号
      // }
      this.qy_data = itemobj
      this.order_type = order_type
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    handleChange(value) {
      console.log(value)
    },
    EnterprisePayment(){
      

      let itemobj = {
        end_it:this.qy_data?this.qy_data.ent_id:this.$store.state.ent_id, // 企业id
        order_type:this.order_type,
        node_num: String(this.numS), // 节点数
        // order_id:'', // 页面生成的订单号
        years:String(this.num), // 年限
        pay_amt:this.money, // 金额
        order_desc:this.order_type=='1'?'续费':'充值', // 订单描述
      }
      let item = JSON.stringify(itemobj)
      // let item = itemobj
        this.$router.push({name:'EnterprisePayment',
            params :{
              item:item
            }
        })
      
      // this.$router.push({path:`/EnterprisePayment/${item}`})
    },
  }
}
</script>

<style scoed lang="scss">
.el-main {

  background: #f6f6fa;
}

.Enterprise-p {
  font-size: 18px;
  color: #999999;
  display: flex;
  margin: 26px 0 30px 0;
  span {
    margin: 4px;
  }
  i {
    background: url('../../../assets/img/din.png') no-repeat;
    width: 23px;
    height: 30px;
    display: block;
    margin-right: 11px;
  }
}
.Enterprise {
  width: 100%;
  background: #fefefe;
  -moz-box-shadow: 0px 0px 10px #e8e8eb;
  -webkit-box-shadow: 0px 0px 10px #e8e8eb;
  box-shadow: 0px 0px 10px #e8e8eb;
  .Enterprise-confirm {
    padding: 22px 34px 51px;
    box-sizing: border-box;
    .Enterprise-state {
      background: url('../../../assets/img/quedin.png');
      width: 1054px;
      height: 34px;
      display: block;
      margin: 0 auto 44px auto;
      display: flex;
      span {
        line-height: 34px;
        color: #fff;
        font-size: 14px;
        width: 33%;
        text-align: center;
      }
    }
    .Enterprise-confirm-type {
      display: flex;
      margin-bottom: 44px;
      span {
        font-size: 16px;
        color: #333333;
        margin: 10px 32px 0 0;
      }
    }
    .Enterprise-confirm-deadline {
      display: flex;
      margin-bottom: 44px;
      & > p {
        font-size: 16px;
        color: #333333;
        margin: 4px 32px 0 0;
      }
      & > span {
        font-size: 16px;
        color: #333333;
        margin: 10px 0 0 11px;
      }
    }
    .Enterprise-money {
      display: flex;
      p {
        font-size: 16px;
        color: #333333;
        margin: 0 32px 0 0;
      }
      span {
        font-size: 21px;
        color: #333333;
        margin-top: 4px;
      }
    }
    .nextStep {
      a {
        width: 100px;
        height: 45px;
        background: #fc4453;
        border-radius: 10px;
        text-align: center;
        font-size: 16px;
        color: #ffffff;
        line-height: 45px;
        margin: 0 auto;
        display: block;
        text-decoration:none;
      }
    }
    p {
      display: flex;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      margin-bottom: 31px;
      span {
        margin-right: 22px;
        &:first-of-type {
          width: 150px;
          display: block;
          text-align: right;
        }
      }
    }
  }
}
</style>
